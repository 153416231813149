<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">CHEQUE VOUCHER</h4></v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12>
          <v-form ref="form" class="multi-col-validation">
            <v-layout row wrap class="mx-1 mb-5" v-if="!has_existing_voucher">
              <v-flex xs12 md12>
                <v-select
                  class="mx-2"
                  v-model="category_id1"
                  dense
                  outlined
                  label="Category"
                  :items="category_items1"
                  item-value="id"
                  item-text="category"
                  :rules="rules.combobox_rule"
                  @change="selected_bank_category"
                ></v-select>
              </v-flex>
              <v-flex xs12 md3>
                <v-select
                  v-model="bank_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank"
                  :items="bank_items"
                  item-value="id"
                  item-text="bank_code"
                  @change="selected_bank"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-flex>
              <v-flex xs12 md3>
                <v-text-field
                  v-if="!bal_loading"
                  v-model="remaining_balance"
                  class="mx-2"
                  dense
                  outlined
                  label="Remaining Balance"
                  readonly
                ></v-text-field>
                <v-progress-circular
                  :size=50
                  :width="5"
                  color="primary"
                  indeterminate
                  v-else
                ></v-progress-circular>
              </v-flex>
              <v-flex xs12 md3>
                <v-checkbox
                  v-model="is_auto_debit"
                  label="Auto Debit Transaction?"
                  dense
                  outlined
                  @change="is_customize_cheque = false"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 md3>
                <v-checkbox
                  v-model="is_customize_cheque"
                  label="Is Customize Cheque?"
                  dense
                  outlined
                  @change="is_auto_debit = false"
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 md5 v-if="!is_auto_debit">
                <v-select
                  v-model="cheque_no"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque From"
                  :items="cheque_items"
                  item-value="cheque_no"
                  item-text="cheque_no"
                  :rules="rules.combobox_rule"
                  @change="selected_cheque"
                ></v-select>
              </v-flex>
              <v-flex xs12 md5 v-else>
                <v-select
                  v-if="!is_auto_debit"
                  v-model="cheque_no"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque From"
                  :items="cheque_items"
                  item-value="cheque_no"
                  item-text="cheque_no"
                  :rules="rules.combobox_rule"
                  @change="selected_cheque"
                  readonly
                ></v-select>
              </v-flex>
              <v-flex xs12 md2>
                <v-text-field
                  v-if="!is_auto_debit"
                  v-model="cheque_counter"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque Counter"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="number"
                  min="1"
                  @change="selected_cheque"
                  @keyup="selected_cheque"
                  @keypress="selected_cheque"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md5>
                <v-select
                  v-if="!is_auto_debit"
                  v-model="cheque_no2"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque To"
                  :items="cheque_items2"
                  item-value="cheque_no"
                  item-text="cheque_no"
                  :rules="rules.combobox_rule"
                  readonly
                ></v-select>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="payee"
                  class="mx-2"
                  dense
                  outlined
                  label="Payee"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="allocation_of_fund"
                  class="mx-2"
                  dense
                  outlined
                  label="Allocation of Fund"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-col cols="12" v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn class="w-full" color="primary" @click="save_voucher" v-if="!saving_data">
                  Create Voucher
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
            </v-layout>
            <v-layout row wrap class="mx-1 mb-5" v-else>
              <v-flex xs12 md12>
                <v-text-field
                  outlined
                  v-model="existing_voucher.voucher_no"
                  class="mx-2"
                  dense
                  label="Voucher #"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.bank.bank_code"
                  class="mx-2"
                  dense
                  outlined
                  label="Bank Code"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md3>
                <v-checkbox
                  v-model="existing_voucher.from_cheque_no === 0"
                  label="Auto Debit Transaction?"
                  dense
                  outlined
                  disabled
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 md3>
                <v-checkbox
                  v-model="existing_voucher.is_customize === 1"
                  label="Is Customize Cheque?"
                  dense
                  outlined
                  disabled
                ></v-checkbox>
              </v-flex>
              <v-flex xs12 md5 v-if="!is_auto_debit">
                <v-text-field
                  v-model="existing_voucher.from_cheque_no"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque # From"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md2 v-if="!is_auto_debit">
                <v-text-field
                  v-model="cheque_counter"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque Counter"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md5 v-if="!is_auto_debit">
                <v-text-field
                  v-model="existing_voucher.to_cheque_no"
                  class="mx-2"
                  dense
                  outlined
                  label="Cheque # To"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.payee"
                  class="mx-2"
                  dense
                  outlined
                  label="Payee"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  v-model="existing_voucher.allocation_of_fund"
                  class="mx-2"
                  dense
                  outlined
                  label="Allocation of Fund"
                  readonly
                ></v-text-field>
              </v-flex>
              <v-col cols="12" v-show="alert">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12" v-if="existing_voucher.request_data.length === 0">
                <v-btn class="w-full" color="error" @click="delete_voucher" v-if="!saving_data">
                  Delete Voucher
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="error" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                md="12"
                v-else-if="
                  (existing_voucher.is_customize === 1 && existing_voucher.is_customize_done === 1) ||
                  existing_voucher.from_cheque_no === 0 ||
                  (existing_voucher.request_data.length > 0 &&
                    existing_voucher.from_cheque_no != 0 &&
                    existing_voucher.is_customize_done === 0 &&
                    existing_voucher.is_customize === 0)
                "
              >
                <v-btn class="w-full" color="info" @click="approve_voucher" v-if="!saving_data">
                  Approve Voucher
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="info" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
              <v-col
                cols="12"
                md="12"
                v-if="
                  existing_voucher.request_data.length > 0 &&
                  existing_voucher.is_customize === 1 &&
                  existing_voucher.is_customize_done === 0
                "
              >
                <v-btn class="w-full" color="success" @click="done_customize_selected"
                       v-if="!saving_data">
                  Done
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="success" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
            </v-layout>
          </v-form>
        </v-flex>
        <v-layout col>
          <v-flex xs12 md3>
            <v-form ref="form2" class="multi-col-validation" v-if="has_existing_voucher">
              <v-toolbar dense dark color="primary">
                <v-toolbar-title><h4 class="font-weight-light">Request Data</h4></v-toolbar-title>
              </v-toolbar>
              <v-select
                v-model="category_id"
                class="mx-2 mt-2"
                dense
                outlined
                label="Category"
                :items="category_items"
                item-value="id"
                item-text="category"
                @change="selected_category"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-select
                v-model="request_id"
                class="mx-2"
                dense
                outlined
                label="Request ID"
                :items="request_items"
                item-value="id"
                item-text="id"
                @change="selected_request"
                :rules="rules.combobox_rule"
              ></v-select>
              <v-col cols="12" v-show="alert_request">
                <v-alert color="warning" text class="mb-0">
                  <div class="d-flex align-start">
                    <v-icon color="warning">
                      {{ icons.mdiAlertOutline }}
                    </v-icon>

                    <div class="ms-3">
                      <p class="text-base font-weight-medium mb-1">
                        {{ alert_message_request }}
                      </p>
                    </div>
                  </div>
                </v-alert>
              </v-col>
              <v-col cols="12" md="12">
                <v-btn class="w-full" color="primary" @click="save_request"
                       v-if="!saving_data_request">
                  Save Request
                </v-btn>
                <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                     v-else></v-progress-circular>
              </v-col>
            </v-form>
            <v-data-table
              dense
              :headers="headers2"
              :items="vouchered_items"
              disable-pagination
              hide-default-footer
              height="530"
            >
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.voucher_no }}
                  </td>
                  <td>
                    {{ item.bank.bank_code }}
                  </td>
                  <td>
                    {{ item.from_cheque_no + ' - ' + item.to_cheque_no }}
                  </td>
                  <td>
                    {{ item.payee }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
          <v-flex xs12 md9 class="mx-2" v-if="data_items.length > 0">
            <h2>Request Data | Total: P {{ total_amount }}</h2>
            <v-data-table dense :headers="headers" :items="data_items">
              <template v-slot:item="{ item }">
                <tr>
                  <td>
                    {{ item.id }}
                  </td>
                  <td>
                    {{ item.request_id }}
                  </td>
                  <td>
                    {{ item.category.category }}
                  </td>
                  <td>
                    {{ item.branch != null ? item.branch.branch_code : '' }}
                  </td>
                  <td>
                    {{ item.particulars.particulars }}
                  </td>
                  <td>
                    {{ item.description }}
                  </td>
                  <td>
                    {{ item.credit_to }}
                  </td>
                  <td>
                    {{ item.payment_for }}
                  </td>
                  <td>
                    {{ item.cheque_date }}
                  </td>
                  <td>
                    {{ item.cheque_no }}
                  </td>
                  <td>
                    {{
                      item.pdc_status === 0 ? 'Not Count:' + formatPrice(item.amount) :
                        formatPrice(item.amount)
                    }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-flex>
        </v-layout>
      </v-layout>
    </v-card>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color"
                      :snackbar_text="this.snackbar_text"/>
    <v-dialog v-model="CustomizeDialog" persistent max-width="50%">
      <v-card>
        <v-card-text>
          <v-form ref="form3" class="multi-col-validation">
            <v-row>
              <v-col md="12" cols="12">
                <v-text-field v-model="customize_cheque_no" label="Cheque #" dense
                              disabled></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="customize_cheque_date"
                  label="Date"
                  dense
                  type="date"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="customize_amount"
                  label="Amount"
                  dense
                  type="number"
                  min="1"
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field
                  v-model="customize_description"
                  label="Description"
                  dense
                  :rules="rules.default_max_600_character_and_no_empty_rule"
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-checkbox v-model="is_change_branch" label="Change Branch?" hide-details
                            class="me-3 mb-4"></v-checkbox>
                <v-text-field v-model="customize_branch" label="Branch" dense
                              disabled v-if="!is_change_branch"></v-text-field>
                <v-select
                  v-if="is_change_branch"
                  outlined
                  class="mx-2"
                  v-model="customize_category_id"
                  dense
                  label="Category"
                  :items="customize_category_items"
                  item-value="id"
                  item-text="category"
                  :rules="rules.combobox_rule"
                  @change="customize_selected_category"
                ></v-select>
                <v-select
                  outlined
                  v-if="!is_forbidden && is_change_branch"
                  class="mx-2"
                  v-model="customize_selection"
                  dense
                  label="Selection"
                  :items="['Branch','Regional','Territory']"
                  :rules="rules.combobox_rule"
                  @change="customize_selected_category"
                ></v-select>
                <v-select
                  v-if="is_change_branch"
                  outlined
                  class="mx-2"
                  v-model="customize_branch_id"
                  dense
                  label="Branch"
                  :items="customize_branch_items"
                  item-value="id"
                  item-text="branch_code"
                  :rules="rules.combobox_rule"
                ></v-select>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field v-model="customize_particulars" label="Particulars" dense
                              disabled></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field v-model="customize_payment_for" label="Payment For" dense
                ></v-text-field>
              </v-col>
              <v-col md="12" cols="12">
                <v-text-field v-model="customize_credit_to" label="Credit To" dense
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-col cols="12" v-show="alert_customize">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message_customize }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="save_customize_data"> Save Changes</v-btn>
      <v-btn color="error" @click="CustomizeDialog = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
import {mdiAccountOutline, mdiAccountCashOutline, mdiInformationOutline} from '@mdi/js'
import moment from 'moment'
import {mapActions, mapGetters} from 'vuex'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import category from '@/store/module/data/category'

const initialState = () => {
  return {
    customize_category_id: 0,
    customize_branch_id: 0,
    customize_selection: '',
    customize_category_items: [],
    customize_branch_items: [],
    CustomizeDialog: false,
    is_forbidden: false,
    is_change_branch: false,
    saving_data: false,
    alert: false,
    alert_message: '',
    alert_customize: false,
    alert_message_customize: '',
    saving_data_request: false,
    alert_request: false,
    alert_message_request: '',
    remaining_balance: '',
    total_amount: 0,
    headers: [
      {text: 'ID', value: 'date_of_deposit', sortable: false},
      {text: 'Request ID', value: 'tin', sortable: false},
      {text: 'Category', value: 'last_name', sortable: false},
      {text: 'Branch', value: 'last_name', sortable: false},
      {text: 'Particulars', value: 'last_name', sortable: false},
      {text: 'Description', value: 'last_name', sortable: false},
      {text: 'Credit To', value: 'last_name', sortable: false},
      {text: 'Payment For', value: 'last_name', sortable: false},
      {text: 'Schedule', value: 'last_name', sortable: false},
      {text: 'Cheque#', value: 'last_name', sortable: false},
      {text: 'Amount', value: 'last_name', sortable: false},
    ],
    headers2: [
      {text: 'Voucher#', value: 'date_of_deposit', sortable: false},
      {text: 'Bank', value: 'last_name', sortable: false},
      {text: 'Cheque#', value: 'last_name', sortable: false},
      {text: 'Payee', value: 'tin', sortable: false},
    ],
    data_items_original: [],
    data_items: [],
    request_id: '',
    request_items: [],
    category_id: '',
    category_items: [],
    allocation_of_fund: '',
    payee: '',
    is_auto_debit: false,
    is_customize_cheque: false,
    cheque_no: '',
    cheque_no2: '',
    cheque_counter: '1',
    voucher_no: '',
    bank_id: '',
    bank_items: [],
    cheque_items: [],
    cheque_items2: [],
    vouchered_items: [],
    cheque_no_id: 0,
    cheque_no2_id: 0,
    category_id1: '',
    category_items1: [],

    has_existing_voucher: false,
    existing_voucher: {},
    customize_payment_for: '',
    customize_credit_to: '',
    customize_particulars: '',
    customize_branch: '',
    customize_description: '',
    customize_amount: '1',
    customize_cheque_date: '',
    customize_cheque_no: '',
    can_approve_customize: false,
    bal_loading: false,
  }
}
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiAccountCashOutline,
        mdiInformationOutline,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['employee_id', 'month_of', 'position']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('cheque_vouchers', [
      'initialize_bank_cheque_voucher',
      'initialize_cheque_voucher',
      'save_request_data_cheque_voucher',
      'approved_cheque_voucher',
      'register_cheque_voucher',
      'delete_cheque_voucher',
      'list_of_cheque_available_on_customize',
      'done_customize_cheque_voucher',
    ]),
    ...mapActions('category', ['list_of_available_for_cheque_voucher']),
    ...mapActions('request_data', ['create_customize_voucher']),
    ...mapActions('bank_transaction', ['trial_and_balance_data_bank']),
    ...mapActions('transaction_months', ['current_month']),
    ...mapActions('branch_information', ['branch_data']),
    ...mapActions('category', ['list_of_all_categories']),
    ...mapActions('salaries_and_wages', ['initialize_salaries_employee']),
    async customize_selected_category() {
      this.is_forbidden = true
      if (this.category_id === 4 || this.category_id === 3) {
        this.is_forbidden = false
      } else {
        this.selection = ''
      }
      this.branch_id = -1
      await this.branch_data({
        category_id: this.customize_category_id,
        is_region: this.customize_selection === 'Regional' ? 1 : 0,
        is_territory: this.customize_selection === 'Territory' ? 1 : 0,
      })
        .then(response => {
          this.customize_branch_items = response.data[0].active
        })
        .catch(error => {
          console.log(error)
        })
    },
    selected_bank_category() {
      this.initialize_bank_cheque_voucher({
        category_id: this.category_id1,
      })
        .then(response => {
          this.bank_items = response.data
          this.category_id = this.category_id1
        })
        .catch(error => {
          console.log(error)
        })
    },
    async initialize_data() {
      this.initialize_salaries_employee()
        .then(response => {
          this.category_items1 = response.data[0].category
          this.category_items1.splice(0, 1)
        })
        .catch(error => {
          console.log(error)
        })
      this.category_id = ''
      this.request_id = ''
      this.request_items = []
      this.can_approve_customize = false
      await this.initialize_cheque_voucher({
        created_id: this.employee_id,
      })
        .then(response => {
          this.vouchered_items = response.data[0].approved_vouchers
          this.existing_voucher = response.data[0].existing
          // this.bank_items = response.data[0].bank_available
          this.has_existing_voucher = this.existing_voucher != null
          if (this.has_existing_voucher) {
            this.cheque_counter = this.existing_voucher.cheque_counter
            this.data_items_original = this.existing_voucher.request_data
            this.data_items = this.data_items_original
            var tto = 0
            var tto_original = 0
            this.data_items.forEach(function (item) {
              if (item.pdc_status === 1) {
                tto += parseFloat(item.amount)
              } else {
                tto_original += parseFloat(item.amount)
              }
            })
            if (tto.toFixed(2) === tto_original.toFixed(2)) {
              this.can_approve_customize = true
            }
            this.customize_amount = tto_original - tto + ''
            this.total_amount = this.formatPrice(tto)
            this.category_id1 = this.existing_voucher.bank.category_id
            this.category_id = this.existing_voucher.bank.category_id
          }
        })
        .catch(error => {
          console.log(error)
        })
      await this.list_of_available_for_cheque_voucher()
        .then(response => {
          this.category_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      await this.selected_category(this.category_id)
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selected_request(value) {
      var index = this.request_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        var datas = []
        var tto = 0
        this.request_items[index].request_data.forEach(function (item) {
          tto += parseFloat(item.amount)
          datas.push({
            id: item.id,
            request_id: item.request_id,
            category: item.category,
            branch: item.branch,
            particulars: item.particulars,
            description: item.description,
            credit_to: item.credit_to,
            payment_for: item.payment_for,
            amount: item.amount,
          })
        })
        this.data_items_original.forEach(function (item) {
          tto += parseFloat(item.amount)
          datas.push({
            id: item.id,
            request_id: item.request_id,
            category: item.category,
            branch: item.branch,
            particulars: item.particulars,
            description: item.description,
            credit_to: item.credit_to,
            payment_for: item.payment_for,
            amount: item.amount,
          })
        })
        this.total_amount = this.formatPrice(tto)
        this.data_items = datas
      }
    },
    selected_category(value) {
      this.request_items = []
      var proceed = false;
      if (this.category_id1 === 4) {
        if ((value === 4 || value === 2)) {
          proceed = true
        }
      } else if (this.category_id1 === value) {
        proceed = true
      }
      if (proceed) {
        var index = this.category_items
          .map(function (x) {
            return x.id
          })
          .indexOf(value)
        if (index != -1) {
          this.request_items = this.category_items[index].request
          var datas = []
          var tto = 0
          this.data_items_original.forEach(function (item) {
            tto += parseFloat(item.amount)
            datas.push({
              id: item.id,
              request_id: item.request_id,
              category: item.category,
              branch: item.branch,
              particulars: item.particulars,
              description: item.description,
              credit_to: item.credit_to,
              payment_for: item.payment_for,
              amount: item.amount,
            })
          })
          this.data_items = datas
          this.total_amount = this.formatPrice(tto)
        }
      }
    },
    delete_voucher() {
      this.saving_data = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('id', this.existing_voucher.id)
        this.delete_cheque_voucher(data)
          .then(() => {
            this.saving_data = false
            Object.assign(this.$data, initialState())
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving_data = false
      }
    },
    save_voucher() {
      this.saving_data = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('bank_id', this.bank_id)
        data.append('cheque_date', this.cheque_date)
        data.append('payee', this.payee.toUpperCase())
        data.append('allocation_of_fund', this.allocation_of_fund.toUpperCase())
        data.append('created_id', this.employee_id)
        data.append('cheque_no', this.cheque_no)
        data.append('cheque_no2', this.cheque_no2)
        data.append('cheque_no_id', this.cheque_no_id)
        data.append('cheque_no2_id', this.cheque_no2_id)
        data.append('cheque_counter', this.cheque_counter)
        data.append('is_customize', this.is_customize_cheque ? 1 : 0)
        if (this.is_auto_debit) {
          data.append('cheque_no', 0)
          data.append('cheque_no2', 0)
          data.append('cheque_no_id', 0)
          data.append('cheque_no2_id', 0)
        }

        this.register_cheque_voucher(data)
          .then(response => {
            var color = 'error'
            if (response.status === 200) {
              var color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.saving_data = false
            Object.assign(this.$data, initialState())
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving_data = false
      }
    },
    async selected_bank(value) {
      this.bal_loading = true
      this.cheque_no = ''
      this.cheque_no2 = ''
      var index = await this.bank_items
        .map(function (x) {
          return x.id
        })
        .indexOf(value)
      if (index != -1) {
        this.cheque_items = await this.bank_items[index].cheque_book_series
        this.cheque_items2 = await this.bank_items[index].cheque_book_series
        if (this.cheque_items.length > 0) {
          this.cheque_no = await this.cheque_items[0].cheque_no
        }
        const data = new FormData()
        this.current_month()
          .then(response => {
            data.append('month_of_id', response.data.id);
            data.append('bank_id', value);
            data.append('is_cash_flow', 1);
            data.append('category_id', -1);
            data.append('view_for_deposit', 0);
            data.append('from_date', moment().subtract(6, 'months').format('YYYY-MM-DD'));
            data.append('to_date', moment().format('YYYY-MM-DD'));
            this.trial_and_balance_data_bank(data)
              .then(response2 => {
                this.remaining_balance = this.formatPrice(response2.data[0].total)
                this.bal_loading = false
              })
              .catch(error => {
                console.log(error)
              })
          })
          .catch(error => {
            console.log(error)
          })
      }
      await this.selected_cheque()
    },
    save_request() {
      this.saving_data_request = true
      this.alert_request = false
      if (this.$refs.form2.validate()) {
        this.save_request_data_cheque_voucher({
          request_id: this.request_id,
          ckv_id: this.existing_voucher.id,
        })
          .then(response => {
            this.saving_data_request = false
            var color = 'error'
            if (response.status === 200) {
              var color = 'success'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.initialize_data()
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert_request = true
        this.alert_message_request = 'Please Fill up the field/s'
        this.saving_data_request = false
      }
    },
    approve_voucher() {
      this.approved_cheque_voucher({
        id: this.existing_voucher.id,
      })
        .then(() => {
          this.change_snackbar({
            show: true,
            color: 'success',
            text: 'SUCCESSFULL APPROVED VOUCHER',
          })
          Object.assign(this.$data, initialState())
          this.initialize_data()
        })
        .catch(error => {
          console.log(error)
        })
    },
    selected_cheque() {
      if (this.cheque_counter < 1) {
        this.cheque_counter = '1'
      }
      var index = this.cheque_items
        .map(function (x) {
          return x.cheque_no
        })
        .indexOf(this.cheque_no)
      if (index != -1) {
        this.cheque_no_id = this.cheque_items[index].id
        var index2 = index + parseFloat(this.cheque_counter) - 1
        if (index2 >= this.cheque_items2.length) {
          this.cheque_counter = (this.cheque_items2.length) + ''
          index2 = (this.cheque_items2.length - 1)
        }
        if (index2 < this.cheque_items2.length) {
          this.cheque_no2 = this.cheque_items2[index2].cheque_no
          this.cheque_no2_id = this.cheque_items2[index2].id
        }
      }
    },
    done_customize_selected() {
      this.list_of_cheque_available_on_customize({
        ckv_id: this.existing_voucher.id,
      })
        .then(response => {
          if (response.data === '') {
            if (this.can_approve_customize) {
              this.done_customize_cheque_voucher({
                id: this.existing_voucher.id,
              })
                .then(() => {
                  this.change_snackbar({
                    show: true,
                    color: 'success',
                    text: 'SUCCESSFULLY DONE THE CUSTOMIZE CHEQUE and YOU CAN APPROVE NOW',
                  })
                  this.initialize_data()
                })
                .catch(error => {
                  console.log(error)
                })
            } else {
              this.change_snackbar({
                show: true,
                color: 'error',
                text: 'NO AVAILABLE CHEQUE AND THE AMOUNT NOT EQUAL TO REQUEST',
              })
            }
          } else {
            this.list_of_all_categories()
              .then(response => {
                this.customize_category_items = response.data
              })
              .catch(error => {
                console.log(error)
              })
            this.CustomizeDialog = true
            this.customize_description = this.data_items_original[0].description
            this.customize_branch = this.data_items_original[0].branch.branch_code
            this.customize_particulars = this.data_items_original[0].particulars.particulars
            this.customize_payment_for = this.data_items_original[0].payment_for
            this.customize_credit_to = this.data_items_original[0].credit_to
            this.customize_cheque_no = response.data.cheque_no
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    save_customize_data() {
      this.alert_customize = false
      if (this.$refs.form3.validate()) {
        const data = new FormData()
        data.append('category_id', this.data_items_original[0].category_id)
        data.append('request_id', this.data_items_original[0].request_id)
        data.append('branch_id', this.is_change_branch ? (this.customize_branch_id) : (this.data_items_original[0].branch_id != null ? this.data_items_original[0].branch_id : ''))
        data.append('particulars_id', this.data_items_original[0].particulars_id != null ? this.data_items_original[0].particulars_id : '')
        data.append('ftv_from_bank_id', this.data_items_original[0].ftv_from_bank_id != null ? this.data_items_original[0].ftv_from_bank_id : '')
        data.append('ftv_to_bank_id', this.data_items_original[0].ftv_to_bank_id != null ? this.data_items_original[0].ftv_to_bank_id : '')
        data.append('description', this.customize_description)
        data.append('payment_for', this.customize_payment_for)
        data.append('amount', this.customize_amount)
        data.append('credit_to', this.customize_credit_to)
        data.append('cheque_bank_id', this.existing_voucher.bank_id)
        data.append('cheque_no', this.customize_cheque_no)
        data.append('cheque_date', this.customize_cheque_date)
        data.append('pdc_status', 1)
        data.append('ca_status', 3)
        data.append('auto_debit_voucher_no', this.data_items_original[0].auto_debit_voucher_no)
        data.append('is_for_not_equal_check', this.data_items_original[0].is_for_not_equal_check)
        data.append('for_not_equal_check_amount', this.data_items_original[0].for_not_equal_check_amount)
        data.append('maf_no', this.data_items_original[0].maf_no)
        data.append('is_damayan_service_paid', this.data_items_original[0].is_damayan_service_paid)
        data.append('is_released_claims', this.data_items_original[0].is_released_claims)
        data.append('is_pending_cheque_booklet', this.data_items_original[0].is_pending_cheque_booklet)
        data.append('is_liquidated', this.data_items_original[0].is_liquidated)
        data.append('is_ftv_deposit_done', this.data_items_original[0].is_ftv_deposit_done)
        data.append('is_ftv_customize', this.data_items_original[0].is_ftv_customize)
        data.append('is_bod_charge', this.data_items_original[0].is_bod_charge)
        data.append('is_ca', this.data_items_original[0].is_ca)
        data.append('ca_employee_id', this.customize_payment_for === this.data_items_original[0].payment_for ? this.data_items_original[0].ca_employee_id : null)
        data.append('cv_id', this.data_items_original[0].cv_id)
        data.append('ckv_id', this.existing_voucher.id)
        data.append('ftv_id', this.data_items_original[0].ftv_id)
        this.create_customize_voucher(data)
          .then(() => {
            this.initialize_data()
            this.CustomizeDialog = false
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        this.alert_customize = true
        this.alert_message_customize = 'Please Fill up the field/s'
      }
    },
  },
}
</script>
